<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.evidencias', 1)}}</span>
        <v-tooltip bottom v-if="habilitarUpload">
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" style="margin-top: -4px !important;" icon v-on="on" @click.stop="$refs.uploadArquivo.open()">
              <v-icon>attach_file</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('title.upload_evidencia') }}</span>
        </v-tooltip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content v-if="showTypeFiles">
      <list-type-files
        @ABRIR_ARQUIVOS="abrirArquivos"
        ref="listTypeFiles"
        :uri="uri"
        v-if="acao.idApuracao"></list-type-files>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <list-files
        @ListFiles_ITENS_ALTERADOS="itensAlterados"
        :habilitar-exclusao="habilitarUpload"
        :tipo-documento="tipoDocumento"
        :habilitar-tipo-arquivo="habilitarTipoArquivo"
        ref="listFiles"
        :uri="uri"
        v-if="acao.idApuracao"></list-files>
      <template v-if="habilitarTipoArquivo">
          <v-btn id="btn_voltar" @click.stop="showTypeFiles=true">{{ $t('label.voltar') }}</v-btn>
      </template>
    </v-expansion-panel-content>
    <upload-file-form
      v-if="habilitarUpload"
      ref="uploadArquivo"
      :title="$t('label.upload_evidencia')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso">
      <v-text-field
        v-model="descricaoArquivo"
        :rules="[rules.required]"
        :counter="150"
        maxlength="150"
        :label="`${$t('label.descricao')}*`"
        required
      ></v-text-field>
      <v-autocomplete
        v-if="habilitarTipoArquivo"
        v-model="tipoDocumento"
        :items="tiposDocumento"
        item-text="descricao"
        item-value="id"
        required
        :rules="[rules.required]"
        :label="`${$t('label.tipo_documento')}*`"
      ></v-autocomplete>
    </upload-file-form>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import basePath from '../../common/functions/api-resource';
import ListFiles from '../../shared-components/files/ListFiles';
import ListTypeFiles from '../../shared-components/files/ListTypeFiles';
import UploadFileForm from '../../shared-components/upload/UploadFileForm';

export default {
  name: 'ApuracaoAcaoArquivos',
  props: {
    acao: Object,
    configuracao: Object,
  },
  data() {
    return {
      esperar: false,
      descricaoArquivo: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      tiposDocumento: [],
      tipoDocumento: 0,
      resource: this.$api.arquivoConfiguracao(this.$resource),
      habilitarTipoArquivo: false,
      showTypeFiles: false,
    };
  },
  components: {
    UploadFileForm,
    ListFiles,
    ListTypeFiles,
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.idAcao}/apuracao/${this.acao.idApuracao}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.idAcao}/apuracao/${this.acao.idApuracao}/upload`;
    },
    habilitarUpload() {
      return (this.canEdit || this.possuiAcessoClienteParaUpload())
        && (this.acao.statusApuracao === 'APURACAO_PREVIA' || this.acao.statusApuracao === 'AGUARDANDO_APURACAO'
          || this.acao.statusApuracao === 'EM_ANALISE');
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
      formData.append('tipoDocumento', encodeURI(this.tipoDocumento));
    },
    itensAlterados(payload) {
      this.$emit('ApuracaoAcaoArquivos_itensAlterados', payload);
    },
    possuiAcessoClienteParaUpload() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CLIENTE_APU_ACAO_CRUD').length;
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      if (this.habilitarTipoArquivo) {
        if (this.$refs.listTypeFiles) {
          setTimeout(() => this.$refs.listTypeFiles.refresh(), 1E3);
        }
      } else if (this.$refs.listFiles) {
        setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      }
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
      this.$emit('ApuracaoAcaoArquivos_uploadSucesso');
    },
    arquivoRemovido() {
      if (this.$refs.listFiles.files.length === 1) {
        this.$emit('ApuracaoAcaoArquivos_arquivosRemovidos');
      }
    },
    abrirArquivos(value) {
      this.tipoDocumento = value;
      this.showTypeFiles = false;
    },
  },
  watch: {
    acao() {
      if (this.$refs.listFiles) {
        if (!this.esperar) {
          this.esperar = true;
          setTimeout(() => {
            if (this.$refs.listFiles) {
              this.$refs.listFiles.refresh();
            }
            this.esperar = false;
          }, 2E2);
        }
      }
    },
  },
  mounted() {
    this.habilitarTipoArquivo = this.configuracao.passo5.apuracao.indTipoDocumentoObrigatorio;
    if (this.habilitarTipoArquivo) {
      this.resource.buscarTiposDocumento().then((res) => {
        this.tiposDocumento = res.body;
      });
      this.showTypeFiles = this.habilitarTipoArquivo;
    }
  },
};
</script>
